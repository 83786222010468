export const publishTrendData = [
  { name: '2016', value: 100 },
  { name: '2017', value: 200 },
  { name: '2018', value: 300 },
  { name: '2019', value: 400 },
  { name: '2020', value: 500 },
  { name: '2021', value: 600 }
]

export const relateDisease = [
  { name: '疾病1', value: 200, pre: 0.2 },
  { name: '疾病2', value: 200, pre: 0.2 },
  { name: '疾病3', value: 200, pre: 0.2 },
  { name: '疾病4', value: 200, pre: 0.2 },
  { name: '疾病5', value: 200, pre: 0.2 }
]

export const impactFactor = [
  { name: '疾病1', value: 200, pre: 0.2 },
  { name: '疾病2', value: 200, pre: 0.2 },
  { name: '疾病3', value: 200, pre: 0.2 },
  { name: '疾病4', value: 200, pre: 0.2 },
  { name: '疾病5', value: 200, pre: 0.2 }
]

export const authorColumns = [
  {
    title: '排名',
    key: 'sort',
    render: (h, params) => {
      return sortRender(h, params)
    }
  },
  {
    title: '作者姓名',
    key: 'label'
    // minWidth: 200
    // render: (h, { row }) => {
    //   const style = {
    //     overFlow: 'hidden',
    //     whiteSpace: 'nowrap',
    //     textOverFlow: 'ellipsis'
    //   }
    //   return (
    //     row.label.length > 72 ? h(Tooltip, { props: { content: row.label, transfer: true } }, [h('span', { style }, row.label)]) : h('span', { style }, row.label)
    //   )
    // }
  },
  {
    title: '发表数',
    align: 'center',
    key: 'value'
  }
]

export const authorData = [
  { sort: '1', author: 'xxxx', count: 333333 },
  { sort: '2', author: 'xxxx', count: 333333 },
  { sort: '3', author: 'xxxx', count: 333333 },
  { sort: '4', author: 'xxxx', count: 333333 },
  { sort: '5', author: 'xxxx', count: 333333 },
  { sort: '6', author: 'xxxx', count: 333333 },
  { sort: '7', author: 'xxxx', count: 333333 },
  { sort: '8', author: 'xxxx', count: 333333 },
  { sort: '9', author: 'xxxx', count: 333333 },
  { sort: '10', author: 'xxxx', count: 333333 }
]

export function sortRender(h, params, page = 1, pageSize = 10) {
  const index = params.index + (page - 1) * pageSize + 1
  const sort = index === 1 || index === 2 || index === 3
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: '#007FFF',
    color: '#fff',
    lineHeight: '20px',
    textAlign: 'center'
  }
  const normal = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '26px',
    height: '26px'
  }
  return h('span', { style: sort ? style : normal }, [index])
}

export const institutionColumns = [
  {
    title: '排名',
    key: 'index',
    render: (h, params) => {
      return sortRender(h, params)
    }
  },
  {
    title: '机构名称',
    key: 'label',
    // ellipsis: true,
    // tooltip: true,
    minWidth: 180,
    render: (h, { row }) => {
      const style = {
        // overflow: 'hidden',
        // whiteSpace: 'nowrap',
        // textOverflow: 'ellipsis'
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        overflow: 'hidden'
      }
      return (
        // row.label.length > 72 ? h(Tooltip, { props: { content: row.label, transfer: true, maxWidth: 240 } }, [h('div', { style }, row.label)]) : h('div', { style }, row.label)
        h('div', { style }, row.label)
      )
    }
  },
  {
    title: '发表数',
    align: 'center',
    key: 'value'
  }
]

export const institutionData = [
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 },
  { institution: 'xxxx', count: 333333 }
]

export const frequencyKeywords = [
  { value: 2, name: '再飞' },
  { value: 3, name: '完白' },
  { value: 4, name: '巴思' },
  { value: 20, name: '张初尘' },
  { value: 21, name: '御术' },
  { value: 2, name: '有田' },
  { value: 4, name: '沉鱼' },
  { value: 2, name: '玉伯' },
  { value: 2, name: '画康' },
  { value: 2, name: '祯逸' },
  { value: 2, name: '绝云' },
  { value: 2, name: '罗宪' },
  { value: 2, name: '萧庆' },
  { value: 2, name: '董珊珊' },
  { value: 2, name: '陆沉' },
  { value: 2, name: '顾倾' },
  { value: 2, name: '再飞' },
  { value: 3, name: '完白' },
  { value: 4, name: '巴思' },
  { value: 2, name: '张初尘' },
  { value: 2, name: '御术' },
  { value: 2, name: '有田' },
  { value: 2, name: '沉鱼' },
  { value: 2, name: '玉伯' },
  { value: 2, name: '画康' },
  { value: 2, name: '祯逸' },
  { value: 2, name: '绝云' },
  { value: 2, name: '罗宪' },
  { value: 2, name: '萧庆' },
  { value: 2, name: '董珊珊' },
  { value: 2, name: '陆沉' },
  { value: 2, name: '顾倾' }
]

export const radial = [
  { name: '问题 1', per: 0.21 },
  { name: '问题 2', per: 0.4 },
  { name: '问题 3', per: 0.49 }
]
