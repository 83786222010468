<template>
  <div :id="chartID" style="flex: 1;width:100%"></div>
</template>

<script>
import DataSet from '@antv/data-set'
import { Chart } from '@antv/g2'
import chinaJson from '../China.json'
export default {
  name: 'Map',

  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartID: {
      type: String,
      default: 'containers'
    },
    height: {
      type: Number,
      default: 450
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData(val) {
      this.chart.destroy()
      if (val.length) {
        this.initMapChart(val)
      }
    }
  },
  mounted() {
    this.initMapChart(this.chartData)
  },

  methods: {
    initMapChart(data) {
      const el = document.getElementById(this.chartID)
      this.chart = new Chart({
        container: this.chartID,
        forceFit: false,
        width: el.clientWidth,
        height: el.clientHeight,
        padding: 20
      })
      this.chart.tooltip({
        showTitle: false,
        showMarkers: false,
        shared: true,
        itemTpl: '<li class="g2-tooltip-list-item">{name}&nbsp;&nbsp;{value}</li>'
      })
      // 同步度量
      this.chart.scale({
        longitude: {
          sync: true
        },
        latitude: {
          sync: true
        }
      })
      this.chart.axis(false)
      this.chart.legend('value', {
        position: 'right-bottom'
      })

      // 绘制世界地图背景
      const ds = new DataSet()
      const chinaMap = ds.createView('back').source(chinaJson, {
        type: 'GeoJSON'
      })
      const chinaMapView = this.chart.createView()
      chinaMapView.data(chinaMap.rows)
      chinaMapView.tooltip(false)
      chinaMapView.polygon().position('longitude*latitude').style({
        fill: '#fff',
        stroke: '#ccc',
        lineWidth: 1
      })
      const userDv = ds.createView().source(data).transform({
        geoDataView: chinaMap,
        field: 'name',
        type: 'geo.region',
        as: ['longitude', 'latitude']
      }).transform({
        type: 'map',
        // 自定义图例变换
        callback: function callback(obj) {
          // obj.trend = obj.value > 100 ? '男性更多' : '女性更多'
          return obj
        }
      })
      const userView = this.chart.createView()
      userView.data(userDv.rows)
      // userView.scale({
      //   trend: {
      //     alias: ''
      //   }
      // })
      userView.polygon().position('longitude*latitude').color('value', ['#d5e8ff', '#007fff']).tooltip('name*value', (name, value) => {
        return {
          name: `地区: ${name}`,
          value: `数量: ${value}`
        }
      }).style({
        fillOpacity: 0.85
      }).animate({
        leave: {
          animation: 'fade-out'
        }
      })
      userView.interaction('element-active')
      this.chart.render()
    }
  }
}
</script>
<style lang='less' scoped>
</style>