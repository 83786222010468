<template>
  <div class="table-container">
    <Table
      :columns="columns"
      :data="data"
      @on-row-click="rowClick"
    />
  </div>
</template>

<script>
import { Table } from 'view-design'
export default {

  data() {
    return {
      tableData: this.data
    }
  },

  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  },

  components: {
    Table
  },

  watch: {
    data(val) {
      if (!val.length) {
        this.tableData = []
        return
      }
      this.tableData = val
    }
  },

  methods: {
    rowClick(params) {
      this.$emit('rowClick', params)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>