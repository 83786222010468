<template>
  <div style="width:100%">
    <div :id="chartID"></div>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'
export default {
  name: 'Curved',

  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartID: {
      type: String,
      default: 'containers'
    },
    height: {
      type: Number,
      default: 400
    },
    lineColor: {
      type: String,
      default: '#fdaf78'
    },
    fillColor: {
      type: String,
      default: '#fff1da'
    },
    tooltip: {
      type: String,
      default: '注册用户数'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData(val) {
      this.chart.destroy()
      if (val.length) {
        this.initCurvedChart(val)
      }
    }
  },
  mounted() {
    this.initCurvedChart(this.chartData)
  },

  methods: {
    initCurvedChart(data) {
      this.chart = new Chart({
        container: this.chartID,
        autoFit: true,
        height: this.height
      })

      this.chart.data(data)
      this.chart.scale({
        year: {
          range: [0, 1]
        },
        value: {
          min: 0,
          nice: true
        }
      })

      this.chart.tooltip({
        showCrosshairs: true, // 展示 Tooltip 辅助线
        shared: true,
        itemTpl: `<li class="g2-tooltip-list-item"><span style="background:${this.lineColor}" class="g2-tooltip-marker"></span>${this.tooltip}<span style="margin-right: 8px"></span>{value}</li>`
      })

      this.chart.line()
        .position('name*value')
        .style({
          stroke: this.lineColor
        })
        .shape('smooth')

      this.chart.area()
        .position('name*value')
        .style({
          fill: this.fillColor
        }).shape('smooth')
      this.chart.render()
    }
  }
}

</script>
<style lang='less' scoped>
</style>