import {
  fetchSystemArea,
  fetchSystemHospitalLevel,
  fetchSystemCompanyType,
  fetchVisitTrend,
  fetchSearchTypeDistribute,
  fetchBioMarkerFocus,
  fetchDiseaseFieldFocus,
  fetchLabelsClickDetail,
  fetchFieldsInfo,
  fetchHotCharts,
  fetchChartClickDetail,
  fetchTopHotSearch,
  fetchTopHotSearchFilter,
  fetchLiteratureRank
} from '@/api'
import { Message } from 'view-design'

import { date } from 'views/analyze/config'
import { office, position, department, part } from 'views/system/config'

import {
  getSelectList,
  setSelected,
  setUserCommonExport
} from '@/utils/tools'

export const analyze = {
  data() {
    return {
      current: '1',
      date,
      dateRange: [],
      bioMarker: '',
      areaOption: {
        area: [],
        areas: []
      },
      unitOption: {
        unit: '全部',
        units: [
          { label: '全部', value: '全部' }
        ]
      },
      hospitalOption: {
        level: '全部',
        levels: [
          { label: '全部', value: '全部' }
        ]
      },
      officeOption: {
        office: '全部',
        offices: getSelectList(office)
      },
      positionOption: {
        position: '全部',
        positions: getSelectList(position)
      },
      departmentOption: {
        department: '全部',
        departments: getSelectList(department)
      },
      partOption: {
        part: '全部',
        parts: getSelectList(part)
      }
    }
  },
  methods: {
    setCommonExport(data, url) {
      const option = {
        ...data,
        is_export: '1'
      }
      setUserCommonExport(option, url)
    },
    // 趋势
    async fetchOriginVisitTrend() {
      this.visitTrend.loading = true
      const option = {
        ...this.filterOption,
        click_type: this.visitRadio === 'all' ? undefined : this.visitRadio
      }
      const res = await fetchVisitTrend(option)
      const { data: { data, sum } } = res
      this.visitTrend.loading = false
      this.visitTrend.data = data
      this.visitTrend.count = sum
    },
    isVisitTrendExport() {
      const option = {
        ...this.filterOption,
        click_type: this.visitRadio === 'all' ? undefined : this.visitRadio
      }
      this.setCommonExport(option, 'users/click')
    },
    // 检索类型分布
    async fetchOriginSearchType() {
      this.searchType.loading = true
      const option = {
        ...this.filterOption
      }
      const res = await fetchSearchTypeDistribute(option)
      const { data: { data, sum } } = res
      this.searchType.loading = false
      this.searchType.data = data
      this.searchType.count = sum
    },
    isSearchTypeExport() {
      const option = {
        ...this.filterOption
      }
      this.setCommonExport(option, 'users/retrieve_subsection')
    },
    // 生物标志物
    async fetchOriginBioMarkerFocus() {
      this.biomarkerFocus.loading = true
      const option = {
        ...this.filterOption,
        page: this.biomarkerFocus.page,
        bio_marker__name__contains: this.bioMarker || undefined
      }
      const res = await fetchBioMarkerFocus(option)
      const { data: { data, sum }, page: { count } } = res.data
      this.biomarkerFocus.loading = false
      this.biomarkerFocus.total = count
      this.biomarkerFocus.count = sum
      this.biomarkerFocus.data = data
    },
    isBioMarkerFocusExport() {
      const option = {
        ...this.filterOption
      }
      this.setCommonExport(option, 'users/bio_marker')
    },
    // 疾病领域
    async fetchOriginDiseaseField() {
      this.fieldFocus.loading = true
      const option = {
        ...this.filterOption
      }
      const res = await fetchDiseaseFieldFocus(option)
      const { data: { data, sum } } = res
      this.fieldFocus.loading = false
      this.fieldFocus.data = data
      this.fieldFocus.count = sum
    },
    isFieldFocusExport() {
      const option = {
        ...this.filterOption
      }
      this.setCommonExport(option, 'users/field')
    },
    // 标签点击
    async fetchOriginFields() {
      const res = await fetchFieldsInfo()
      const { data } = res
      this.fields = data.map(v => ({
        value: v.id,
        label: v.name,
        children: v.items.map(i => ({
          value: i.id,
          label: i.name
        }))
      }))
    },
    async fetchOriginLabelClick() {
      this.labelClicks.loading = true
      const option = {
        ...this.filterOption,
        field_id: this.field.length > 1 ? this.field[1] : this.field[0] || undefined
      }
      const res = await fetchLabelsClickDetail(option)
      const { data: { data, sum } } = res
      this.labelClicks.loading = false
      this.labelClicks.data = data
      this.labelClicks.count = sum
    },
    isLabelClicksExport() {
      const option = {
        ...this.filterOption,
        field_id: this.field.length > 1 ? this.field[1] : this.field[0] || undefined
      }
      this.setCommonExport(option, 'users/index')
    },
    // 热门图表
    async fetchOriginHotCharts() {
      this.hotCharts.loading = true
      const option = {
        ...this.filterOption
      }
      const res = await fetchHotCharts(option)
      const { data: { data, sum } } = res
      this.hotCharts.loading = false
      this.hotCharts.data = data
      this.hotCharts.count = sum
    },
    isHotChartsExport() {
      const option = {
        ...this.filterOption
      }
      this.setCommonExport(option, 'users/figures')
    },
    // 图表点击详情
    async fetchOriginChartClickDetail() {
      this.chartsDetails.loading = true
      const option = {
        ...this.filterOption,
        figure_type: this.detail,
        page: this.chartsDetails.page
      }
      const res = await fetchChartClickDetail(option)
      const { data: { data, sum }, page: { count } } = res.data
      this.chartsDetails.loading = false
      this.chartsDetails.total = count
      this.chartsDetails.count = sum
      this.chartsDetails.data = data
    },
    isChartDetailsExport() {
      const option = {
        ...this.filterOption,
        figure_type: this.detail
      }
      this.setCommonExport(option, 'users/figure')
    },
    // 文献点击 收藏
    async fetchOriginLiteratureRank() {
      this.literatureRank.loading = true
      const option = {
        ...this.filterOption,
        is_click: this.rank,
        page: this.literatureRank.page
      }
      const res = await fetchLiteratureRank(option)
      const { data: { data, sum }, page: { count } } = res.data
      this.literatureRank.loading = false
      this.literatureRank.total = count
      this.literatureRank.count = sum
      this.literatureRank.data = data
    },
    isLiteratureRankExport() {
      const option = {
        ...this.filterOption,
        is_click: this.rank
      }
      this.setCommonExport(option, 'users/review')
    },
    // Top热门搜索词
    async fetchOriginHotSearch() {
      this.hotSearch.loading = true
      const option = {
        ...this.filterOption
      }
      const res = await fetchTopHotSearch(option)
      const { data: { data, sum } } = res
      this.hotSearch.loading = false
      this.hotSearch.data = data
      this.hotSearch.count = sum
    },
    isHotSearchExport() {
      const option = {
        ...this.filterOption
      }
      this.setCommonExport(option, 'users/keywords')
    },
    // 高级检索
    async fetchOriginHotSearchFilter() {
      this.advanceSearch.loading = true
      const option = {
        ...this.filterOption
      }
      const res = await fetchTopHotSearchFilter(option)
      const { data: { data, sum } } = res
      this.advanceSearch.loading = false
      this.advanceSearch.data = data
      this.advanceSearch.count = sum
    },
    isAdvanceSearchExport() {
      const option = {
        ...this.filterOption
      }
      this.setCommonExport(option, 'users/advanced_key')
    },
    async fetchOriginArea() {
      const res = await fetchSystemArea({ key: 'province' })
      this.areaOption.areas = setSelected(res.data)
    },
    async fetchOriginLevel() {
      const res = await fetchSystemHospitalLevel({ key: 'company_level' })
      this.hospitalOption.levels = [...this.hospitalOption.levels, ...setSelected(res.data)]
    },
    async fetchOriginCompanyType() {
      const res = await fetchSystemCompanyType()
      this.unitOption.units = [...this.unitOption.units, ...setSelected(res.data)]
    },
    handleDateItem(key, value) {
      this.current = key
      this.dateRange = []
    },
    handleDate(date) {
      this.dateRange = date
      this.current = ''
    },
    isAreaChange() {
      if (this.areaOption.area.length > 5) {
        Message.warning({
          content: '最多选择五个！',
          duration: 2
        })
        this.areaOption.area = this.areaOption.area.slice(0, 5)
      }
    },
    isClear() {
      this.current = '1'
      this.dateRange = []
      this.areaOption.area = []
      this.unitOption.unit = '全部'
      this.hospitalOption.level = '全部'
      this.officeOption.office = '全部'
      this.positionOption.position = '全部'
      this.departmentOption.department = '全部'
      this.partOption.part = '全部'
      this.isSearch()
    },
    commonReset() {
      this.visitRadio = 'all'
      this.biomarkerFocus.page = 1
      this.field = []
      this.detail = '0'
      this.rank = '1'
      this.literatureRank.page = 1
    },
    isSearch() {
      this.commonReset()
      this.fetchOriginVisitTrend()
      this.fetchOriginSearchType()
      this.fetchOriginBioMarkerFocus()
      this.fetchOriginDiseaseField()
      this.fetchOriginLabelClick()
      this.fetchOriginHotCharts()
      this.fetchOriginChartClickDetail()
      this.fetchOriginLiteratureRank()
      this.fetchOriginHotSearch()
      this.fetchOriginHotSearchFilter()
    }
  }
}