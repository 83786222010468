<template>
  <div class="pie-wrap">
    <div style="flex:0.6">
      <div :id="chartID"></div>
    </div>
    <div style="flex:0.4" class="custom-legend" v-if="isShowCustomLegend">
      <ul class="legend" :style="legendStyle">
        <li class="legend-item" v-for="(item, index) in legendData" :key="index" @click="handleLegendItemClick(item)">
          <!-- @click="handleLegendClick(item, index)" -->
          <Row type="flex">
            <Col>
              <span class="dot" :style="{ backgroundColor: item.checked ? item.color : '#aaa' }"></span>
              <Tooltip theme="light" transfer :content="item.name" placement="bottom" max-width="200">
                <span class="name">{{ item.name.length > 20 ? item.name.slice(0, 15) : item.name }}</span>
              </Tooltip>
            </Col>
            <Col>
              <Divider type="vertical" />
              <!-- <span class="persent" v-if='item.per'>{{ item.per }}</span> -->
              <span class="value" style="margin-right: 8px;">{{ item.num }}</span>
              <span class="value">{{ showUnit ? item.value + '%' : item.value }}</span>
            </Col>
          </Row>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Divider, Row, Col, Tooltip } from 'view-design'
import { Chart, registerShape, registerInteraction } from '@antv/g2'
export default {
  name: 'Pie',
  components: {
    Divider,
    Row,
    Col,
    Tooltip
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
      required: true
    },
    type: {
      type: String,
      default: 'pie'
    },
    chartID: {
      type: String,
      default: 'containers',
      required: true
    },
    subTitle: {
      type: String,
      default: '修改点'
    },
    count: {
      type: Number,
      default: 0
    },
    color: {
      type: Array,
      default: () => [
        '#8543E0',
        '#F04864',
        '#FACC14',
        '#2FC25B',
        '#13C2C2',
        '#00B4ED',
        '#6a7cd0',
        '#fdbe8e',
        '#8543E0',
        '#7044C7',
        '#5D45AE',
        '#4A4695',
        '#37477C',
        '#244863',
        '#11494A',
        '#004A31',
        '#0D6945',
        '#1A7A59',
        '#279B6D',
        '#34AC81',
        '#41BD95',
        '#4ECEA9',
        '#5BDFBD',
        '#68F0D1',
        '#75F1E5',
        '#82F2F9',
        '#8FF3FD',
        '#9CF4FF'
      ]
    },
    height: {
      type: Number,
      default: 400
    },
    isShowCustomLegend: {
      type: Boolean,
      default: true
    },
    innerRadius: {
      type: Number,
      default: 0
    },
    legendStyle: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    lengendLength: {
      type: Number,
      default: 0
    },
    showUnit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chart: null,
      legendData: []
    }
  },
  watch: {
    chartData(val) {
      if (val.length) {
        this.chart.changeData(val)
        this.chart.render()
        if (this.isShowCustomLegend) {
          this.legendData =
            this.lengendLength === 0
              ? this.chartData.map((item, index) => {
                const colorIndex = index % this.color.length
                console.log(item, 'item chartData')
                return {
                  name: item.name,
                  value: item.value,
                  per: item.per
                    ? Math.floor(item.per)
                      ? Number(item.per * 100) + '%'
                      : Math.floor(item.per * 100) + '%'
                    : null,
                  checked: true,
                  color: this.color[colorIndex],
                  ...item
                }
              })
              : this.chartData.slice(0, this.lengendLength).map((item, index) => {
                const colorIndex = index % this.color.length
                return {
                  name: item.name,
                  value: item.value,
                  per: item.per
                    ? Math.floor(item.per)
                      ? Number(item.per * 100) + '%'
                      : Math.floor(item.per * 100) + '%'
                    : null,
                  checked: true,
                  color: this.color[colorIndex],
                  ...item
                }
              })
        }
      }
    }
  },
  methods: {
    handleLegendClick(item, index) {
      const newItem = item
      newItem.checked = !newItem.checked
      this.$set(this.legendData, index, newItem)
      const filteredLegendData = this.legendData.filter(l => l.checked).map(l => l.name)
      if (this.chart) {
        this.chart.filter('name', value => filteredLegendData.indexOf(`${value}`) > -1)
        // this.chart.forceFit() //重新渲染失败，应该是G2的渲染bug，更改为render触发
        this.chart.render()
      }
    },
    handleLegendItemClick(item) {
      if (item.name) {
        this.$emit('pie', item)
      }
    }
  },
  created() {
    if (this.isShowCustomLegend) {
      this.legendData =
        this.lengendLength === 0
          ? this.chartData.map((item, index) => {
            const colorIndex = index % this.color.length
            return {
              ...item,
              name: item.name,
              value: item.value,
              per: item.per
                ? Math.floor(item.per)
                  ? Number(item.per * 100) + '%'
                  : Math.floor(item.per * 100) + '%'
                : null,
              checked: true,
              color: this.color[colorIndex]
            }
          })
          : this.chartData.slice(0, this.lengendLength).map((item, index) => {
            const colorIndex = index % this.color.length
            return {
              ...item,
              name: item.name,
              value: item.value,
              per: item.per
                ? Math.floor(item.per)
                  ? Number(item.per * 100) + '%'
                  : Math.floor(item.per * 100) + '%'
                : null,
              checked: true,
              color: this.color[colorIndex]
            }
          })
    }
  },
  mounted() {
    // 实例化图表
    this.chart = new Chart({
      container: this.chartID,
      height: this.height,
      autoFit: true,
      appendPadding: 10
    })

    if (this.type === 'pie') {
      this.chart.coordinate('theta', {
        radius: 0.7,
        innerRadius: this.innerRadius
      })
    }
    if (this.type === 'rose') {
      this.chart.coordinate('polar')
    }
    // 装填数据
    this.chart.data(this.chartData)
    // 设置图表浮层
    this.chart.tooltip({
      showMarkers: false,
      showTitle: false,
      itemTpl:
        '<li class="g2-tooltip-list-item" data-index={index} style="list-style-type: none; padding: 0px; margin: 12px 0px;"><span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>{name}: {num} {value}</li>',
      customItems: items => {
        return items.map(item => ({
          ...item,
          value: this.showUnit ? item.value + '%' : item.value,
          num: item.data.num
        }))
      }
    })
    // 设置图列
    if (this.isShowCustomLegend) {
      this.chart.legend(false)
    } else {
      this.chart.legend({
        position: 'right',
        offsetX: -20
      })
    }
    // 绘制图形属性
    if (this.innerRadius) {
      const sliceNumber = 0.005
      // 自定义 other 的图形，增加两条线
      registerShape('interval', 'slice-shape', {
        draw(cfg, container) {
          const points = cfg.points
          let path = []
          path.push(['M', points[0].x, points[0].y])
          path.push(['L', points[1].x, points[1].y - sliceNumber])
          path.push(['L', points[2].x, points[2].y - sliceNumber])
          path.push(['L', points[3].x, points[3].y])
          path.push('Z')
          path = this.parsePath(path)
          return container.addShape('path', {
            attrs: {
              fill: cfg.color,
              path
            }
          })
        }
      })
      // 辅助文本
      if (this.count) {
        this.chart
          .annotation()
          .text({
            position: ['50%', '50%'],
            content: this.subTitle,
            style: {
              fontSize: 14,
              fill: '#b5b5b6',
              textAlign: 'center'
            },
            offsetY: -20
          })
          .text({
            position: ['50%', '50%'],
            content: this.count,
            style: {
              fontSize: 24,
              fill: '#3d3d3d',
              textAlign: 'center'
            },
            offsetY: 10
          })
      }
      this.chart
        .interval()
        .position('value')
        .color('name', this.color)
        .adjust('stack')
        .shape('slice-shape')
      this.chart.interaction('element-single-selected')
    } else {
      if (this.type === 'pie') {
        this.chart
          .interval()
          .position('value')
          .color('name', this.color)
          .adjust('stack')
      }
      if (this.type === 'rose') {
        this.chart.axis(false)
        // 绘制图形属性
        this.chart
          .interval()
          .position('name*value')
          .color('name', this.color)
          .style({
            lineWidth: 1,
            stroke: '#fff'
          })
      }
    }
    registerInteraction('cursor-cus', {
      showEnable: [
        { trigger: 'plot:mouseenter', action: 'cursor:pointer' },
        { trigger: 'plot:mouseleave', action: 'cursor:default' }
      ]
    })
    this.chart.interaction('cursor-cus')
    // 设置内置交互
    this.chart.removeInteraction('legend-active')
    // 渲染图形
    this.chart.render()

    // *绑定点击事件
    this.chart.on('click', event => {
      if (event.data) {
        const {
          data: { data }
        } = event
        this.$emit('pie', data)
      }
    })
  },
  destroyed() {
    this.chart.destroy()
    // *解绑点击事件
    this.chart.off('click')
  }
}
</script>

<style lang="less" scoped>
.pie-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  .custom-legend {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin-left: 64px;
    .legend {
      // margin-right: 36px;
      padding: 0;
      list-style: none;
      .legend-item {
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        .name {
          display: block;
          width: 100px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .dot {
      position: relative;
      top: -1px;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 8px;
    }
    .persent {
      color: rgba(0, 0, 0, 0.45);
      margin-right: 10px;
    }
    .value {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  :global(.ivu-row-flex) {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1660px) {
  .pie-wrap {
    .custom-legend {
      margin-left: 20px;
      .legend {
        .legend-item {
          .name {
            width: 85px;
          }
        }
      }
    }
  }
}
</style>
