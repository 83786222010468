<template>
  <div class="analyze-page-wrap">
    <div class="header-wrap">
      <div class="label-wrap">
        <div class="label">选择日期：</div>
        <div class="value">
          <DateItem :lists="date" :activeKey="current" @active="handleDateItem" />
          <DatePicker
            style="width: 240px"
            type="daterange"
            v-model="dateRange"
            :clearable="false"
            placeholder="起始时间~结束时间"
            @on-change="handleDate"
          />
        </div>
      </div>
      <div class="btn-wrap">
        <Button type="default" style="margin-right: 12px" @click="handleClear">清空</Button>
        <Button type="primary" @click="handleSearch">搜索</Button>
      </div>
    </div>
    <div class="content-wrap">
      <ChardCard
        label="访问趋势"
        cardId="visit"
        :count="visitTrend.count"
        :showCount="true"
        :loading="visitTrend.loading"
        :export="() => handleExport('visit', '访问趋势')"
      >
        <Curved
          lineColor="#1d8eff"
          fillColor="#deefff"
          style="margin-top: 24px"
          chartID="visitTrend"
          :height="300"
          tooltip="访问次数"
          :chartData="visitTrend.data"
        />
      </ChardCard>
      <Row :gutter="16">
        <Col span="12">
          <ChardCard
            label="一级标签点击分布"
            cardId="firstLabel"
            :count="firstLabel.count"
            :showCount="true"
            :loading="firstLabel.loading"
            :export="() => handleExport('firstLabel', '一级标签点击分布')"
          >
            <template #right>
              <div class="label-wrap">
                <div class="label">生物标志物</div>
                <div class="value">
                  <Select v-model="firstLabel.current" style="width: 160px;" @on-change="handleFirstChange">
                    <Option v-for="item in biomarkerSelect" :key="item.value" :value="item.value">{{
                      item.label
                    }}</Option>
                  </Select>
                </div>
              </div>
            </template>
            <Pie
              :count="firstLabel.count"
              subTitle=""
              chartID="intelligenzeSearch"
              :height="580"
              :innerRadius="0.8"
              :chartData="firstLabel.data"
            />
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="二级标签点击分布"
            cardId="secondLabel"
            :count="secondLabel.count"
            :showCount="true"
            :loading="secondLabel.loading"
            :export="() => handleExport('secondLabel', '二级标签点击分布')"
          >
            <template #right>
              <Row :gutter="8">
                <Col>
                  <div class="label-wrap">
                    <div class="label">生物标志物</div>
                    <div class="value">
                      <Select v-model="secondLabel.current" style="width: 160px;" @on-change="handleSeclectChange">
                        <Option v-for="item in biomarkerSelect" :key="item.value" :value="item.value">{{
                          item.label
                        }}</Option>
                      </Select>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div class="label-wrap">
                    <div class="label">一级标签</div>
                    <div class="value">
                      <Select
                        v-model="secondLabel.firstLabel"
                        style="width: 160px;"
                        @on-change="handleSeclectLableChange"
                      >
                        <Option v-for="item in firstLabelSelect" :key="item.value" :value="item.value">{{
                          item.label
                        }}</Option>
                      </Select>
                    </div>
                  </div>
                </Col>
              </Row>
            </template>
            <Pie
              :count="secondLabel.count"
              subTitle=""
              chartID="intelligenzeSearch2"
              :height="580"
              :innerRadius="0.8"
              :chartData="secondLabel.data"
            />
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <ChardCard
            label="二级标签点击排行"
            cardId="secondLabelClick"
            :count="secondLabelClick.count"
            :showCount="true"
            :loading="secondLabelClick.loading"
            :export="() => handleExport('secondLabelClick', '二级标签点击排行')"
          >
            <template #right>
              <div class="label-wrap">
                <div class="label">生物标志物</div>
                <div class="value">
                  <Select
                    v-model="secondLabelClick.current"
                    style="width: 160px;"
                    @on-change="handleSecondLabelClickChange"
                  >
                    <Option v-for="item in biomarkerSelect" :key="item.value" :value="item.value">{{
                      item.label
                    }}</Option>
                  </Select>
                </div>
              </div>
            </template>
            <RankTabel :columns="columns" :data="secondLabelClick.data" />
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="生物标志物关注排行"
            cardId="biomarker"
            :count="biomarkerData.count"
            :showCount="true"
            :loading="biomarkerData.loading"
            :export="() => handleExport('biomarkerData', '生物标志物关注排行')"
          >
            <Histogram :size="32" chartID="fieldFocusHistogram" :chartData="biomarkerData.data" />
          </ChardCard>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { DatePicker, Button, Row, Col, Select, Option } from 'view-design'
import { Curved, Pie, Histogram } from 'components/Charts'
import dayjs from 'dayjs'
import DateItem from 'views/system/components/DateItem'
import ChardCard from 'components/ChardCard'
import RankTabel from 'components/Table'
import { sortRender } from '@/views/literature/components/Dashboard/config'
import { BaseServe } from '@/server'
import { saveAs } from 'file-saver'
import {
  thinkTankTrend,
  thinkTankFirst,
  thinkTankSecond,
  thinkTankSecondRank,
  thinkTankBiomarker,
  thinkTankBiomarkerSelect,
  thinkTankFirstSelect
} from '@/api/tracking/'

export default {
  components: {
    DateItem,
    DatePicker,
    Button,
    ChardCard,
    Curved,
    Row,
    Col,
    RankTabel,
    Pie,
    Histogram,
    Select,
    Option
  },
  data() {
    return {
      date: [
        {
          key: '1',
          label: '今天',
          value: '1'
        },
        {
          key: '7',
          label: '近7天',
          value: '7'
        },
        {
          key: '30',
          label: '近30天',
          value: '30'
        }
      ],
      current: '7',
      dateRange: [],
      columns: [
        {
          title: '排名',
          key: 'index',
          render: (h, params) => {
            return sortRender(h, params)
          },
          width: 120
        },
        {
          title: '二级标签名称',
          align: 'center',
          key: 'name'
        },
        {
          title: '一级标签名称',
          align: 'center',
          key: 'parent'
        },
        {
          title: '点击数',
          align: 'center',
          key: 'value'
        }
      ],
      visitTrend: {
        count: 0,
        loading: false,
        data: []
      },
      firstLabel: {
        count: 0,
        loading: false,
        data: [],
        current: ''
      },
      secondLabel: {
        count: 0,
        loading: false,
        data: [],
        current: '',
        firstLabel: ''
      },
      secondLabelClick: {
        count: 0,
        loading: false,
        data: [],
        current: ''
      },
      biomarkerData: {
        count: 0,
        loading: false,
        data: []
      },
      biomarkerSelect: [],
      firstLabelSelect: [],
      exportUrlMap: {
        visit: '/mind_lib/behavior/dashboard/trend/export/',
        firstLabel: '/mind_lib/behavior/dashboard/first_label/export/',
        secondLabel: '/mind_lib/behavior/dashboard/second_label/export/',
        secondLabelClick: '/mind_lib/behavior/dashboard/second_label_rank/export/',
        biomarkerData: '/mind_lib/behavior/dashboard/biomarker/export/'
      }
    }
  },
  computed: {
    dateParams() {
      if (this.current) {
        return {
          created_at__date__gte: dayjs()
            .subtract(this.current, 'day')
            .format('YYYY-MM-DD'),
          created_at__date__lte: dayjs().format('YYYY-MM-DD')
        }
      }
      return {
        created_at__date__gte: dayjs(this.dateRange[0]).format('YYYY-MM-DD'),
        created_at__date__lte: dayjs(this.dateRange[1]).format('YYYY-MM-DD')
      }
    }
  },
  created() {
    const biomarkerSelct = thinkTankBiomarkerSelect().then(res => {
      this.biomarkerSelect = res.data.data.map(item => {
        return {
          label: item.name,
          value: item.value
        }
      })
      this.firstLabel.current = res.data.data[0].value
      this.secondLabel.current = res.data.data[0].value
      this.secondLabelClick.current = res.data.data[0].value
    })
    const firstSelect = thinkTankFirstSelect().then(res => {
      this.firstLabelSelect = res.data.data.map(item => {
        return {
          label: item.name,
          value: item.value
        }
      })
      this.secondLabel.firstLabel = res.data.data[0].value
    })
    Promise.all([biomarkerSelct, firstSelect]).then(() => {
      this.fetchData()
    })
  },
  methods: {
    fetchData() {
      this.visitTrend.loading = true
      thinkTankTrend(this.dateParams).then(res => {
        this.visitTrend.count = res.data.all_count
        this.visitTrend.loading = false
        this.visitTrend.data = res.data.data
      })
      this.firstLabel.loading = true
      thinkTankFirst({
        ...this.dateParams,
        label__biomarker__id: this.firstLabel.current
      }).then(res => {
        this.firstLabel.count = res.data.all_count
        this.firstLabel.loading = false
        this.firstLabel.data = res.data.data
      })
      this.secondLabel.loading = true
      console.log({
        ...this.dateParams,
        label__biomarker__id: this.secondLabel.current,
        label__parent_id: this.secondLabel.firstLabel
      })
      thinkTankSecond({
        ...this.dateParams,
        label__biomarker__id: this.secondLabel.current,
        label__parent_id: this.secondLabel.firstLabel
      }).then(res => {
        this.secondLabel.count = res.data.all_count
        this.secondLabel.loading = false
        this.secondLabel.data = res.data.data
      })
      this.secondLabelClick.loading = true
      thinkTankSecondRank({
        ...this.dateParams,
        label__biomarker__id: this.secondLabelClick.current
      }).then(res => {
        this.secondLabelClick.count = res.data.all_count
        this.secondLabelClick.loading = false
        this.secondLabelClick.data = res.data.data
      })
      this.biomarkerData.loading = true
      thinkTankBiomarker(this.dateParams).then(res => {
        this.biomarkerData.count = res.data.all_count
        this.biomarkerData.loading = false
        this.biomarkerData.data = res.data.data
      })
    },
    handleSeclectChange() {
      this.secondLabel.loading = true
      thinkTankSecond({
        ...this.dateParams,
        label__biomarker__id: this.secondLabel.current,
        label__parent_id: this.secondLabel.firstLabel
      }).then(res => {
        this.secondLabel.count = res.data.all_count
        this.secondLabel.loading = false
        this.secondLabel.data = res.data.data
      })
    },
    handleSeclectLableChange() {
      this.secondLabel.loading = true
      thinkTankSecond({
        ...this.dateParams,
        label__biomarker__id: this.secondLabel.current,
        label__parent_id: this.secondLabel.firstLabel
      }).then(res => {
        this.secondLabel.count = res.data.all_count
        this.secondLabel.loading = false
        this.secondLabel.data = res.data.data
      })
    },
    handleFirstChange() {
      this.firstLabel.loading = true
      thinkTankFirst({
        ...this.dateParams,
        label__biomarker__id: this.firstLabel.current
      }).then(res => {
        this.firstLabel.count = res.data.all_count
        this.firstLabel.loading = false
        this.firstLabel.data = res.data.data
      })
    },
    handleSecondLabelClickChange() {
      this.secondLabelClick.loading = true
      thinkTankSecondRank({
        ...this.dateParams,
        label__biomarker__id: this.secondLabelClick.current
      }).then(res => {
        this.secondLabelClick.count = res.data.all_count
        this.secondLabelClick.loading = false
        this.secondLabelClick.data = res.data.data
      })
    },
    handleDateItem(key) {
      this.current = key
      this.dateRange = []
    },
    handleDate(date) {
      this.dateRange = date
      this.current = ''
    },
    handleClear() {
      this.dateRange = []
      this.current = '7'
      this.fetchData()
    },
    handleSearch() {
      this.fetchData()
    },
    handleExport(type, name) {
      BaseServe({
        url: this.exportUrlMap[type],
        method: 'get',
        responseType: 'blob',
        params: {
          ...this.dateParams,
          label__biomarker__id: this[type].current,
          label__parent_id: this[type].firstLabel
        }
      }).then(res => {
        saveAs(res, `${name}.xlsx`)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.analyze-page-wrap {
  margin-bottom: 24px;
}
.header-wrap {
  padding: 22px 32px;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  display: flex;
  justify-content: space-between;

  .label-wrap {
    display: flex;
    align-items: center;

    .label {
      color: #969fa8;
      font-size: 16px;
    }

    .value {
      display: flex;
    }
  }
}
.content-wrap {
  margin-top: 12px;
  background-color: #fff;
  padding: 32px;
  border-radius: 12px;

  .label-wrap {
    display: flex;
    align-items: center;

    .label {
      color: #333;
      font-size: 16px;
      margin-right: 8px;
    }

    .value {
      display: flex;
    }
  }
}
.pager-footer {
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
}
</style>
