export const tabs = [
  {
    key: '0',
    label: '账户管理'
  },
  {
    key: '1',
    label: '角色管理'
  },
  {
    key: '2',
    label: '用户反馈'
  },
  {
    key: '3',
    label: '文献上传'
  },
  {
    key: '4',
    label: '智库上传'
  },
  {
    key: '5',
    label: '会议上传'
  },
  {
    key: '6',
    label: '标签管理'
  },
  {
    key: '7',
    label: '主题管理'
  },
  {
    key: '8',
    label: '菜单管理'
  }
]

export const cards = [
  {
    icon: 'icondanrixinzeng',
    label: '昨日新增',
    count: 0,
    c1: '#85C2F7',
    c2: '#00B8FF'
  },
  {
    icon: 'iconleijiyonghu',
    label: '累计用户',
    count: 0,
    c1: '#3AD5B1',
    c2: '#2CDC9C'
  }
]

export const date = [
  {
    key: '0',
    label: '近7天',
    value: '7'
  },
  {
    key: '1',
    label: '近30天',
    value: '30'
  }
]

export const units = [
  { name: '非医院', value: 500 },
  { name: '医院', value: 750 },
  { name: '自定义填写', value: 1000 }
]

export const distribute = [
  { name: '疾病1', value: 902, per: 0.1 },
  { name: '疾病2', value: 900, per: 0.2 },
  { name: '疾病3', value: 750, per: 0.1 },
  { name: '疾病4', value: 600, per: 0.2 },
  { name: '疾病5', value: 400, per: 0.2 },
  { name: '疾病6', value: 100, per: 0.2 }
]

export const levels = [
  { name: '三级', value: 3000, per: 0.3 },
  { name: '二级', value: 3000, per: 0.3 },
  { name: '一级', value: 3000, per: 0.3 },
  { name: '其他', value: 1000, per: 0.1 }
]

export const offices = [
  { name: '科室1', value: 100 },
  { name: '科室2', value: 90 },
  { name: '科室3', value: 80 },
  { name: '科室4', value: 70 },
  { name: '科室5', value: 60 },
  { name: '科室6', value: 50 },
  { name: '科室7', value: 40 },
  { name: '科室8', value: 30 },
  { name: '科室9', value: 20 },
  { name: '科室10', value: 10 },
  { name: '科室11', value: 40 },
  { name: '科室12', value: 30 },
  { name: '科室13', value: 20 },
  { name: '科室14', value: 10 },
  { name: '科室15', value: 10 },
  { name: '科室16', value: 10 },
  { name: '科室17', value: 10 },
  { name: '科室18', value: 10 },
  { name: '科室19', value: 10 }
]

export const useAreaTableColumns = [
  {
    title: '省份',
    align: 'center',
    key: 'province'
  },
  {
    title: '用户数',
    align: 'center',
    key: 'value'
  },
  {
    title: '占比',
    align: 'center',
    key: 'pre',
    render: (h, { row }) => {
      return (
        h('span', {}, row.pre * 100 + '%')
      )
    }
  }
]

export const useAreaTable = [
  { province: '上海市', value: 10, pre: 0.1 },
  { province: '安徽省', value: 10, pre: 0.1 },
  { province: '江西省', value: 10, pre: 0.1 },
  { province: '广东省', value: 10, pre: 0.1 },
  { province: '浙江省', value: 10, pre: 0.1 },
  { province: '江苏省', value: 10, pre: 0.1 },
  { province: '贵州省', value: 10, pre: 0.1 },
  { province: '重庆市', value: 10, pre: 0.1 },
  { province: '北京市', value: 10, pre: 0.1 },
  { province: '河北省', value: 10, pre: 0.1 }
]

export const users = [
  {
    id: 1,
    name: '张三',
    role: '普通用户',
    company: '杭州费尔斯通科技有限公司',
    part: '创新市场部',
    position: '市场经理',
    mail: 'huos@hsmap.com',
    mobile: '18318309132',
    time: '2020-12-12 12:44:23',
    forbidden: true
  }
]

export const roles = [
  { label: '全部', value: '全部' },
  { label: '普通角色', value: '普通角色' },
  { label: '角色', value: '角色' }
]

export const office = ['全部', '检验科', '病理科', '急诊', '心内科', '心胸外科', '呼吸科', '感染科', '妇科', '产科', '消化科', '儿科', '骨科', '肿瘤科', '内分泌科', '普外科', '肾内科', '生殖中心', '神经内科', '影像科', '老年科', '其它']

export const position = ['全部', '主任医师', '主任技师', '副主任医师', '副主任技师', '主治医师', '住院医师', '主管技师', '技师', '其它']

export const department = ['全部', '医学部', '市场部', '销售部', '研发部', '其它部门']

export const part = ['全部', '总监', '部门经理', '专员', '职员/助理', '其它']