<template>
  <div class="date-wrap">
    <div class="date-item"
    v-for="item in lists"
    :key="item.key"
    @click="handleItem(item.key, item.value)"
    :class="[activeKey === item.key ? 'active': '']"
    >{{item.label}}</div>
  </div>
</template>

<script>
export default {
  name: 'DateItem',

  props: {
    lists: {
      type: Array,
      default: () => []
    },
    activeKey: {
      type: String,
      default: '0'
    }
  },

  methods: {
    handleItem(key, value) {
      this.$emit('active', key, value)
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>