<template>
  <div style="width:100%">
    <div :id="chartID" :style="{ width }"></div>
  </div>
</template>

<script>
import { Chart, registerInteraction } from '@antv/g2'
export default {
  name: 'RadialBar',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartID: {
      type: String,
      default: 'containers'
    },
    color: {
      type: String,
      default: '#27BFEF'
    },
    size: {
      type: Number,
      default: 48
    },
    height: {
      type: Number,
      default: 400
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    title: {
      type: String,
      default: ''
    },
    innerRadius: {
      type: Number,
      default: 0.6
    },
    radius: {
      type: Number,
      default: 0.7
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData(val) {
      if (val.length) {
        this.chart.changeData(val)
        this.chart.render()
      }
    }
  },
  mounted() {
    let maxActual = 0
    this.chartData.forEach(item => {
      if (item.percent > maxActual) {
        maxActual = item.percent
      }
    })
    // *实例化图表
    this.chart = new Chart({
      container: this.chartID,
      height: this.height,
      autoFit: true,
      appendPadding: 40
    })
    // *装填数据
    this.chart.data(this.chartData, {
      percent: {
        min: 0,
        max: 1
      }
    })
    this.chart.tooltip({
      title: 'name'
    })
    // *设置图列
    this.chart.legend(false)
    this.chart.coordinate('polar', {
      radius: this.radius,
      innerRadius: this.innerRadius, // 图表中心缩放
      startAngle: -1 * Math.PI / 2, // 极坐标的起始角度，单位为弧度
      endAngle: 2 * Math.PI / 2 // 极坐标的结束角度，单位为弧度
    }).transpose()

    this.chart.interval()
      .position('name*per')
      .color('per', '#26BFEF-#FAD337-#4DCB73')
      .tooltip('per', function(val) {
        return {
          name: '占比',
          value: Math.floor(val * 100) + '%'
        }
      })
      .label('per', {
        offset: -5
      })
    this.chartData.map((obj) => {
      this.chart.annotation().text({
        position: [obj.name, 0],
        content: obj.name + ' ',
        style: {
          textAlign: 'right'
        }
      })
    })
    registerInteraction('cursor-cus', {
      showEnable: [
        { trigger: 'plot:mouseenter', action: 'cursor:pointer' },
        { trigger: 'plot:mouseleave', action: 'cursor:default' }
      ]
    })
    this.chart.interaction('cursor-cus')

    this.chart.render()
    // *绑定点击事件
    this.chart.on('click', event => {
      if (event.data) {
        const { data: { data } } = event
        this.$emit('radial', data)
      }
    })
  },
  destroyed() {
    // *销毁chart实例
    this.chart.destroy()
    // *解绑点击事件
    this.chart.off('click')
  }
}
</script>
