<template>
  <div class="analyze-wrap">
    <div class="header-wrap">
      <div class="row">
        <div class="item date">
          <div class="label">选择日期：</div>
          <div class="value date-value">
            <DateItem
              :lists="date"
              :activeKey="current"
              @active="handleDateItem"
            />
            <DatePicker
              style="width: 240px"
              type="daterange"
              v-model="dateRange"
              :clearable="false"
              placeholder="请选择起始时间"
              @on-change="handleDate"
            />
          </div>
        </div>
        <div class="item area">
          <div class="label">用户地区：</div>
          <Select v-model="areaOption.area" multiple @on-change="isAreaChange" placeholder="全部">
            <Option v-for="item in areaOption.areas" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
        <div class="item btn">
          <Button type="default" style="margin-right: 12px" @click="isClear">清空</Button>
          <Button type="primary" @click="isSearch">搜索</Button>
        </div>
      </div>
      <div class="row-two" v-if="unitOption.unit === 1">
        <div class="item">
          <div class="label">医院等级：</div>
          <Select v-model="hospitalOption.level">
            <Option v-for="item in hospitalOption.levels" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
        <div class="item">
          <div class="label gab">科室：</div>
          <Select v-model="officeOption.office" style="margin-left: -24px">
            <Option v-for="item in officeOption.offices" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
        <div class="item">
          <div class="label gab">职称：</div>
          <Select v-model="positionOption.position" style="margin-left: -24px">
            <Option v-for="item in positionOption.positions" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
      </div>
      <div class="row-two" v-if="unitOption.unit === 2">
        <div class="item">
          <div class="label">部门：</div>
          <Select v-model="departmentOption.department">
            <Option v-for="item in departmentOption.departments" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
        <div class="item">
          <div class="label gab">职位：</div>
          <Select v-model="partOption.part" style="margin-left: -24px">
            <Option v-for="item in partOption.parts" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
      </div>
    </div>
    <!-- <Header @search="handleSearch"/> -->
    <div class="analyze-chart-wrap">
        <!-- :export="isCompanyTypeExport" -->
      <ChardCard
        label="访问趋势"
        cardId="visit"
        :count="visitTrend.count"
        :showCount="true"
        :loading="visitTrend.loading"
        :export="isVisitTrendExport"
      >
      <template #right>
        <RadioGroup style="height: 24px" v-model="visitRadio" type="button" @on-change="handleVisit">
          <Radio :label="item.value" v-for="item in visitsRadios" :key="item.value">{{item.label}}</Radio>
        </RadioGroup>
      </template>
        <Curved
          lineColor="#1d8eff"
          fillColor="#deefff"
          style="margin-top: 24px"
          chartID="visitTrend"
          :height="300"
          tooltip="访问次数"
          :chartData="visitTrend.data"
        />
      </ChardCard>
      <Row :gutter="24">
        <Col span="12">
            <!-- :export="isHospiatlLevelExport" -->
          <ChardCard
            label="检索类型分布"
            cardId="searchType"
            :count="searchType.count"
            :showCount="true"
            :loading="searchType.loading"
            :export="isSearchTypeExport"
          >
            <Pie
              :count="searchType.count"
              subTitle="智能检索"
              chartID="intelligenzeSearch"
              :height="580"
              :innerRadius="0.8"
              :chartData="searchType.data"
            />
          </ChardCard>
        </Col>
        <Col span="12">
            <!-- :export="isOfficesRegisterExport" -->
          <ChardCard
            label="生物标志物关注度"
            cardId="biomarkerFocus"
            :count="biomarkerFocus.count"
            :showCount="true"
            :isTable="true"
            :loading="biomarkerFocus.loading"
            :export="isBioMarkerFocusExport"
          >
            <template #right>
              <Input v-model="bioMarker" @on-search="handleInputSearch" search placeholder="请输入Biomarker名称" style="width: 300px" />
            </template>
            <Table
              :columns="bioMarkerColumns"
              :data="biomarkerFocus.data"
            />
            <div class="pager-footer">
              <Page
                :current="biomarkerFocus.page"
                :total="biomarkerFocus.total"
                show-elevator
                @on-change="biomarkerFocusPageChange"
              />
            </div>
          </ChardCard>
        </Col>
      </Row>
      <ChardCard
        label="疾病领域关注度"
        cardId="fieldFocus"
        :count="fieldFocus.count"
        :showCount="true"
        :loading="fieldFocus.loading"
        :export="isFieldFocusExport"
      >
        <Histogram
          :size="32"
          chartID="fieldFocusHistogram"
          :chartData="fieldFocus.data"
        />
      </ChardCard>
        <!-- :export="exportFrequencyWords" -->
      <ChardCard
        label="指标点击详情"
        cardId="labelClicks"
        :showCount="true"
        :count="labelClicks.count"
        :loading="labelClicks.loading"
        :export="isLabelClicksExport"
      >
        <template #right>
          <div class="item">
            <Cascader
              :data="fields"
              v-model="field"
              @on-change="handleFields"
            ></Cascader>
          </div>
        </template>
        <WordCloud
          chartID="labelClicksCloud"
          :isTooltip="true"
          :chartData="labelClicks.data"
        />
      </ChardCard>
      <Row :gutter="24">
        <Col span="12">
            <!-- :export="isHospiatlLevelExport" -->
          <ChardCard
            label="看板热门图表"
            cardId="hotCharts"
            :count="hotCharts.count"
            :showCount="true"
            :isTable="true"
            :loading="hotCharts.loading"
            :export="isHotChartsExport"
          >
            <Table
              :columns="hotChartsColumns"
              :data="hotCharts.data"
            />
            <!-- <div class="pager-footer">
              <Page
                :current="hotCharts.page"
                :total="hotCharts.total"
                show-elevator
                @on-change="hotChartsPageChange"
              />
            </div> -->
          </ChardCard>
        </Col>
        <Col span="12">
            <!-- :export="isOfficesRegisterExport" -->
          <ChardCard
            label="图表点击详情"
            cardId="chartsDetails"
            :count="chartsDetails.count"
            :showCount="true"
            :isTable="true"
            :loading="chartsDetails.loading"
            :export="isChartDetailsExport"
          >
            <template #right>
              <Select v-model="detail" style="width:200px" @on-change="handleChartDetail">
                <Option v-for="item in details" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </template>
            <Table
              :columns="chartsDetailsColumns"
              :data="chartsDetails.data"
            />
            <div class="pager-footer">
              <Page
                :current="chartsDetails.page"
                :total="chartsDetails.total"
                show-elevator
                @on-change="chartsDetailsPageChange"
              />
            </div>
          </ChardCard>
        </Col>
      </Row>
      <ChardCard
        cardId="rank"
        :count="literatureRank.count"
        :showCount="true"
        :isTable="true"
        :isToggle="true"
        :loading="literatureRank.loading"
        :export="isLiteratureRankExport"
      >
        <template #custom>
          <Menu style="height: 52px;margin-top:-20px" mode="horizontal" theme="light" :active-name="rank" @on-select="handleLiteratureRank">
            <MenuItem style="color: #494E53" v-for="item in ranks" :key="item.value" :name="item.value">
                {{item.label}}排行
            </MenuItem>
          </Menu>
        </template>
        <Table
          :columns="rankColumns"
          :data="literatureRank.data"
        />
        <div class="pager-footer">
          <Page
            :current="literatureRank.page"
            :total="literatureRank.total"
            show-elevator
            @on-change="literatureRankPageChange"
          />
        </div>
      </ChardCard>
      <Row :gutter="24">
        <Col span="12">
            <!-- :export="isHospiatlLevelExport" -->
          <ChardCard
            label="TOP10热门搜索词"
            cardId="hotSearch"
            :count="hotSearch.count"
            :showCount="true"
            :isTable="true"
            :loading="hotSearch.loading"
            :export="isHotSearchExport"
          >
            <Table
              :columns="hotSearchColumns"
              :data="hotSearch.data"
            />
          </ChardCard>
        </Col>
        <Col span="12">
            <!-- :export="isOfficesRegisterExport" -->
          <ChardCard
            label="高级检索热门筛选"
            cardId="advanceSearch"
            :count="advanceSearch.count"
            :showCount="true"
            :loading="advanceSearch.loading"
            :export="isAdvanceSearchExport"
          >
            <Pie
              :count="advanceSearch.count"
              subTitle="热门筛选"
              chartID="advanceSearchPie"
              :height="532"
              :innerRadius="0.8"
              :chartData="advanceSearch.data"
            />
          </ChardCard>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import DateItem from 'views/system/components/DateItem'
import {
  getAssignFormatTime
} from '@/utils/tools'
import ChardCard from 'components/ChardCard'
import Table from 'components/Table'
import { Curved, Histogram, Pie, WordCloud } from 'components/Charts'
import { visitsRadios, bioMarkerColumns, hotChartsColumns, chartsDetailsColumns, details, ranks, rankColumns, hotSearchColumns } from '../config'
import { RadioGroup, Radio, Row, Col, Page, Cascader, Select, Option, Menu, MenuItem, DatePicker, Button, Input } from 'view-design'
import { analyze } from '@/mixins/analyze'
export default {
  name: 'Analyze',
  mixins: [analyze],
  data () {
    return {
      visitRadio: 'all',
      visitsRadios,
      visitTrend: {
        count: 0,
        loading: true,
        data: []
      },
      searchType: {
        count: 0,
        loading: true,
        data: []
      },
      biomarkerFocus: {
        count: 0,
        loading: true,
        page: 1,
        total: 0,
        data: []
      },
      fieldFocus: {
        count: 0,
        loading: true,
        data: []
      },
      field: [],
      fields: [],
      labelClicks: {
        count: 0,
        loading: true,
        data: []
      },
      hotCharts: {
        count: 0,
        loading: true,
        data: []
      },
      detail: '0',
      details,
      chartsDetails: {
        count: 0,
        loading: true,
        data: [],
        page: 1,
        total: 0
      },
      rank: '1',
      ranks,
      literatureRank: {
        count: 0,
        loading: true,
        data: [],
        page: 1,
        total: 0
      },
      hotSearch: {
        count: 0,
        data: [],
        loading: true
      },
      advanceSearch: {
        count: 0,
        data: [],
        loading: true
      },
      bioMarkerColumns,
      hotChartsColumns,
      chartsDetailsColumns,
      rankColumns,
      hotSearchColumns
    }
  },
  components: {
    ChardCard,
    Curved,
    RadioGroup,
    Radio,
    Row,
    Col,
    Pie,
    Table,
    Page,
    Histogram,
    WordCloud,
    Cascader,
    Menu,
    MenuItem,
    DateItem,
    DatePicker,
    Select,
    Option,
    Button,
    Input
  },

  computed: {
    setDateType({ current }) {
      // if (!current) {
      //   return 'default'
      // } else {
      //   return current === '0' ? 'week' : 'month'
      // }
      let temp = ''
      switch (current) {
        case '0':
          temp = 'date'
          break
        case '1':
          temp = 'week'
          break
        case '2':
          temp = 'month'
          break
        default:
          temp = 'default'
          break
      }
      return temp
    },
    setChartRelateParams({ dateRange }) {
      console.log(dateRange)
      return {
        created_at__date__gte: (dateRange.length && dateRange[0]) ? dateRange[0] : getAssignFormatTime(1, undefined, this.setDateType),
        created_at__date__lte: (dateRange.length && dateRange[1]) ? dateRange[1] : getAssignFormatTime(1, undefined)
      }
    },
    filterOption({ setChartRelateParams, areaOption, unitOption, hospitalOption, officeOption, positionOption, partOption, departmentOption }) {
      return {
        ...setChartRelateParams,
        user__province__in: areaOption.area.length ? areaOption.area.join(',') : undefined,
        user__company_type: unitOption.unit === '全部' ? undefined : unitOption.unit,
        user__company_level: hospitalOption.level === '全部' || unitOption.unit !== 1 ? undefined : hospitalOption.level,
        // user__department: officeOption.office === '全部' || unitOption.unit !== 1 ? undefined : officeOption.office,
        // user__position: positionOption.position === '全部' || unitOption.unit !== 1 ? undefined : positionOption.position,
        user__position: unitOption.unit === '全部'
          ? undefined
          : unitOption.unit === 1
            ? positionOption.position === '全部'
              ? undefined
              : positionOption.position
            : unitOption.unit === 2
              ? partOption.part === '全部'
                ? undefined
                : partOption.part
              : undefined,
        user__department: unitOption.unit === '全部'
          ? undefined
          : unitOption.unit === 1
            ? officeOption.office === '全部'
              ? undefined
              : officeOption.office
            : unitOption.unit === 2
              ? departmentOption.department === '全部'
                ? undefined
                : departmentOption.department
              : undefined
      }
    }
  },

  created() {
    this.fetchOriginArea()
    this.fetchOriginCompanyType()
    this.fetchOriginLevel()
    this.fetchOriginVisitTrend()
    this.fetchOriginSearchType()
    this.fetchOriginBioMarkerFocus()
    this.fetchOriginDiseaseField()
    this.fetchOriginFields()
    this.fetchOriginLabelClick()
    this.fetchOriginHotCharts()
    this.fetchOriginChartClickDetail()
    this.fetchOriginLiteratureRank()
    this.fetchOriginHotSearch()
    this.fetchOriginHotSearchFilter()
  },

  methods: {
    handleSearch(option) {
      console.log(option)
    },
    handleVisit(val) {
      this.visitRadio = val
      this.fetchOriginVisitTrend()
    },
    biomarkerFocusPageChange(val) {
      this.biomarkerFocus.page = val
      this.fetchOriginBioMarkerFocus()
    },
    handleFields(val) {
      this.field = val
      this.fetchOriginLabelClick()
    },
    hotChartsPageChange(val) {
      this.hotCharts.page = val
    },
    handleChartDetail(val) {
      this.detail = val
      this.fetchOriginChartClickDetail()
    },
    chartsDetailsPageChange(val) {
      this.chartsDetails.page = val
      this.fetchOriginChartClickDetail()
    },
    literatureRankPageChange(val) {
      this.literatureRank.page = val
      this.fetchOriginLiteratureRank()
    },
    handleLiteratureRank(name) {
      this.rank = name
      this.literatureRank.page = 1
      this.fetchOriginLiteratureRank()
    },
    handleInputSearch() {
      this.fetchOriginBioMarkerFocus()
    }
  }
}

</script>
<style lang='less' scoped>
.ivu-menu-horizontal.ivu-menu-light:after {
  background: #fff;
}
@import url('./index.less');
</style>
