import { sortRender } from '../literature/components/Dashboard/config'
export function sortOrderRender(h, params) {
  const { order } = params.row
  const sort = order === 1 || order === 2 || order === 3
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: '#007FFF',
    color: '#fff',
    lineHeight: '20px',
    textAlign: 'center'
  }
  const normal = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px'
  }
  return h('span', { style: sort ? style : normal }, [order])
}
export const date = [
  {
    key: '0',
    label: '今天',
    value: '1'
  },
  {
    key: '1',
    label: '近7天',
    value: '7'
  },
  {
    key: '2',
    label: '近30天',
    value: '30'
  }
]

export const visitsRadios = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '文献列表',
    value: '0'
  },
  {
    label: '数据看板',
    value: '1'
  },
  {
    label: '图书馆',
    value: '2'
  }
]

export const visitData = [
  { name: '2021-06-07', value: 1, freq: 0.5 },
  { name: '2021-06-08', value: 2, freq: 0 },
  { name: '2021-06-09', value: 3, freq: 0 },
  { name: '2021-06-10', value: 1, freq: 0.5 }
]

export const searchData = [
  { name: '疾病1', value: 902, per: 0.1 },
  { name: '疾病2', value: 900, per: 0.2 },
  { name: '疾病3', value: 750, per: 0.1 },
  { name: '疾病4', value: 600, per: 0.2 },
  { name: '疾病5', value: 400, per: 0.2 },
  { name: '疾病6', value: 100, per: 0.2 }
]

export const fieldFocusData = [
  { name: '疾病1', value: 902, per: 0.1 },
  { name: '疾病2', value: 900, per: 0.2 },
  { name: '疾病3', value: 750, per: 0.1 },
  { name: '疾病4', value: 600, per: 0.2 },
  { name: '疾病5', value: 400, per: 0.2 },
  { name: '疾病6', value: 100, per: 0.2 }
]

export const labelClicksData = [
  { value: 2, name: '再飞' },
  { value: 3, name: '完白' },
  { value: 4, name: '巴思' },
  { value: 20, name: '张初尘' },
  { value: 21, name: '御术' },
  { value: 2, name: '有田' },
  { value: 4, name: '沉鱼' },
  { value: 2, name: '玉伯' },
  { value: 2, name: '画康' },
  { value: 2, name: '祯逸' },
  { value: 2, name: '绝云' },
  { value: 2, name: '罗宪' },
  { value: 2, name: '萧庆' },
  { value: 2, name: '董珊珊' },
  { value: 2, name: '陆沉' },
  { value: 2, name: '顾倾' },
  { value: 2, name: '再飞' },
  { value: 3, name: '完白' },
  { value: 4, name: '巴思' },
  { value: 2, name: '张初尘' },
  { value: 2, name: '御术' },
  { value: 2, name: '有田' },
  { value: 2, name: '沉鱼' },
  { value: 2, name: '玉伯' },
  { value: 2, name: '画康' },
  { value: 2, name: '祯逸' },
  { value: 2, name: '绝云' },
  { value: 2, name: '罗宪' },
  { value: 2, name: '萧庆' },
  { value: 2, name: '董珊珊' },
  { value: 2, name: '陆沉' },
  { value: 2, name: '顾倾' }
]

export const bioMarkerColumns = [
  {
    title: '排名',
    key: 'index',
    render: (h, params) => {
      return sortOrderRender(h, params)
    },
    width: 120
  },
  {
    title: 'Biomarker名称',
    align: 'center',
    key: 'bio_marker'
  },
  {
    title: '所属疾病领域',
    align: 'center',
    key: 'field'
  },
  {
    title: '搜索数',
    align: 'center',
    key: 'value'
  }
]

export const hotChartsColumns = [
  {
    title: '排名',
    key: 'index',
    render: (h, params) => {
      return sortRender(h, params)
    },
    width: 120
  },
  {
    title: '图表名称',
    align: 'center',
    key: 'name'
  },
  {
    title: '点击数',
    align: 'center',
    key: 'value'
  }
]

export const chartsDetailsColumns = [
  {
    title: '排名',
    key: 'index',
    render: (h, params) => {
      return sortOrderRender(h, params)
    },
    width: 120
  },
  {
    title: '图表元素',
    align: 'center',
    key: 'name'
  },
  {
    title: '点击数',
    align: 'center',
    key: 'value'
  }
]

export const biomarkerData = [
  { biomarker: 'xxxx1', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx2', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx3', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx4', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx5', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx6', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx7', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx8', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx9', field: 'xxxxxxxx', count: 123347 },
  { biomarker: 'xxxx10', field: 'xxxxxxxx', count: 123347 }
]

export const hotChartData = [
  { chart_name: 'xxxx1', count: 123347 },
  { chart_name: 'xxxx2', count: 123347 },
  { chart_name: 'xxxx3', count: 123347 },
  { chart_name: 'xxxx4', count: 123347 },
  { chart_name: 'xxxx5', count: 123347 },
  { chart_name: 'xxxx6', count: 123347 },
  { chart_name: 'xxxx7', count: 123347 },
  { chart_name: 'xxxx8', count: 123347 },
  { chart_name: 'xxxx9', count: 123347 },
  { chart_name: 'xxxx10', count: 123347 }
]

export const chartsDetailsData = [
  { chart_name: 'xxxx1', count: 123347 },
  { chart_name: 'xxxx2', count: 123347 },
  { chart_name: 'xxxx3', count: 123347 },
  { chart_name: 'xxxx4', count: 123347 },
  { chart_name: 'xxxx5', count: 123347 },
  { chart_name: 'xxxx6', count: 123347 },
  { chart_name: 'xxxx7', count: 123347 },
  { chart_name: 'xxxx8', count: 123347 },
  { chart_name: 'xxxx9', count: 123347 },
  { chart_name: 'xxxx10', count: 123347 }
]

export const fields = [
  {
    value: '602',
    label: '肿瘤',
    children: [
      {
        value: '603',
        label: '肺癌'
      },
      {
        value: '604',
        label: '乳腺癌'
      },
      {
        value: '605',
        label: '肝癌'
      },
      {
        value: '606',
        label: '胃/结直肠癌'
      },
      {
        value: '607',
        label: '卵巢癌'
      },
      {
        value: '608',
        label: '宫颈癌'
      }
    ]
  },
  {
    value: '603',
    label: '心血管疾病'
  }
]

export const details = [
  {
    label: '发表趋势',
    value: '0'
  },
  {
    label: '文献类型',
    value: '6'
  },
  {
    label: '影响因子分布',
    value: '7'
  },
  {
    label: '作者排行',
    value: '1'
  },
  {
    label: '机构排行',
    value: '2'
  },
  {
    label: '相关疾病',
    value: '5'
  },
  {
    label: '临床应用',
    value: '3'
  },
  {
    label: '语言类型',
    value: '9'
  },
  {
    label: '研究人群',
    value: '4'
  },
  {
    label: '高频关键词',
    value: '8'
  }
]

export const ranks = [
  {
    label: '文献点击',
    value: '1'
  },
  {
    label: '文献收藏',
    value: '0'
  }
]

export const rankColumns = [
  {
    title: '排名',
    key: 'index',
    render: (h, params) => {
      return sortOrderRender(h, params)
    },
    width: 120
  },
  {
    title: 'PMID',
    align: 'center',
    key: 'PMID',
    render: (h, { row }) => {
      return h('span', {}, row.PMID || '-')
    }
  },
  {
    title: '杂志',
    align: 'center',
    key: 'journal'
  },
  {
    title: '发表年份',
    align: 'center',
    key: 'publish_year'
  },
  {
    title: '文献题名',
    align: 'center',
    key: 'title',
    tooltip: true
  },
  {
    title: '点击量',
    align: 'center',
    key: 'num'
  }
]

export const rankData = [
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 },
  { pmid: 11111, magazine: 'suibiaojiaoshenmezazhi', publish_year: 2021, literature: 'Palbociclib in combination with letrozole in patients with estrogen receptor–positive, human epidermal growth factor receptor 2–negative advanced breast cancer', count: 12354 }
]

export const hotSearchColumns = [
  {
    title: '排名',
    key: 'index',
    render: (h, params) => {
      return sortRender(h, params)
    },
    width: 120
  },
  {
    title: '搜索关键词',
    align: 'center',
    key: 'name'
  },
  {
    title: '搜索数',
    align: 'center',
    key: 'value'
  }
]

export const hotSearchData = [
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 },
  { keyword: '关键词1', count: 2333 }
]

export const advanceSearchData = [
  { name: '疾病1', value: 902, per: 0.1 },
  { name: '疾病2', value: 900, per: 0.2 },
  { name: '疾病3', value: 750, per: 0.1 },
  { name: '疾病4', value: 600, per: 0.2 },
  { name: '疾病5', value: 400, per: 0.2 },
  { name: '疾病6', value: 100, per: 0.2 }
]