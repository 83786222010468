<template>
  <div style="width:100%">
    <div :id="chartID" :style="{ width }"></div>
  </div>
</template>

<script>
import { Chart, registerInteraction } from '@antv/g2'
import { textColor, axisLineColor } from '../config'
export default {
  name: 'Histogram',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartID: {
      type: String,
      default: 'containers'
    },
    color: {
      type: String,
      default: '#27BFEF'
    },
    size: {
      type: Number,
      default: 48
    },
    height: {
      type: Number,
      default: 400
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    // *图表滚动
    overScroll: {
      type: Boolean,
      default: false
    },
    sliceLen: {
      type: Number,
      default: 7
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData(val) {
      if (val.length) {
        this.chart.changeData(val)
        this.chart.render()
      }
    }
  },
  mounted() {
    // *实例化图表
    this.chart = new Chart({
      container: this.chartID,
      height: this.height,
      autoFit: true,
      appendPadding: 40
    })
    // *装填数据
    this.chart.data(this.chartData)
    // 设置度量
    this.chart.scale({
      name: {
        type: 'cat'
      },
      // 设置度量 y轴范围分布过大 最小区间内无法点击
      value: {
        min: 0,
        base: 2,
        type: 'log',
        nice: true
      }
    })
    // *设置图表浮层
    this.chart.tooltip({
      showMarkers: false,
      showTitle: false,
      itemTpl: `<li class="g2-tooltip-list-item"><span style="background:${this.color}" class="g2-tooltip-marker"></span>{title}<span style="margin-right: 8px"></span>{value}</li>`
    })
    // *设置图列
    this.chart.legend(false)
    // 设置X轴
    this.chart.axis('name', {
      line: {
        style: {
          stroke: axisLineColor
        }
      },
      label: {
        style: {
          fill: textColor
        },
        autoEllipsis: true,
        autoRotate: true,
        // *格式化？
        formatter: val =>
          this.overScroll
            ? `${val.length > this.sliceLen ? val.slice(0, 4) + '...' : val}`
            : `${val}`
      },
      tickLine: null
    })
    // *设置Y轴
    this.chart.axis('value', {
      grid: {
        line: {
          style: {
            stroke: axisLineColor,
            lineDash: [4, 5]
          }
        }
      }
    })
    // *增加交互语法
    this.chart.interaction('active-region')
    registerInteraction('cursor-cus', {
      showEnable: [
        { trigger: 'plot:mouseenter', action: 'cursor:pointer' },
        { trigger: 'plot:mouseleave', action: 'cursor:default' }
      ]
    })
    this.chart.interaction('cursor-cus')
    // *绘制图形属性
    this.chart
      .interval()
      .position('name*value')
      // .color('value', '#3F99EF-#5EABF3-#64D9FF-#7BDFFF')
      .style({
        fill: 'l(90) 0:#64D9FF 1:#3F99EF',
        fillOpacity: 0.85
      })
      .size(this.size)
    this.chart.render()

    // *绑定点击事件
    this.chart.on('click', event => {
      if (event.data) {
        const { data: { data } } = event
        this.$emit('histogram', data)
      }
    })
  },
  destroyed() {
    // *销毁chart实例
    this.chart.destroy()
    // *解绑点击事件
    this.chart.off('click')
  }
}
</script>
