<template>
  <div class="page-wrap">
    <div class="tabs-wrap">
      <div
        class="tab-item"
        v-for="item in tabs"
        :key="item.value"
        :class="{ 'tab-active': item.value === activeName }"
        @click="() => (activeName = item.value)"
      >
        {{ item.name }}
      </div>
    </div>
    <LiteratureAnalyze v-if="activeName === '1'" />
    <MeetingAnalyze v-if="activeName === '2'" />
    <ZhikuAnalyze v-if="activeName === '3'" />
  </div>
</template>

<script>
import LiteratureAnalyze from './literature/'
import MeetingAnalyze from './meeting/'
import ZhikuAnalyze from './zhiku/'

export default {
  components: {
    LiteratureAnalyze,
    MeetingAnalyze,
    ZhikuAnalyze
  },
  data() {
    return {
      activeName: '1',
      tabs: [
        { name: '文献库', value: '1' },
        { name: '会议速递', value: '2' },
        { name: '智库', value: '3' }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.page-wrap {
  padding-top: 24px;
}
.tabs-wrap {
  display: flex;
  background-color: #fff;
  padding: 0 22px;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #e5e5e5;

  .tab-item {
    cursor: pointer;
    font-size: 16px;
    color: #8e9ca9;
    height: 60px;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  .tab-active {
    transition: color 0.3s ease-in-out;
    color: #007fff;
    font-weight: 700;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 32px;
      height: 2px;
      background-color: #007fff;
    }
  }
}
</style>
