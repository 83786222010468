<template>
  <div class="chart-card-wrap" :id="cardId">
    <div class="chart-card-header">
      <div class="left">
        <div class="chart-card-label" v-if="!isToggle">
          {{label}}
        </div>
        <div class="chart-card-toggle" v-else>
          <slot name="custom"></slot>
        </div>
        <div class="chart-icons" v-if="count">
          <template v-if="isExport">
            <Tooltip transfer content='导出' placement="bottom">
              <i class="iconfont iconxiazai1" @click="exportHandle"></i>
            </Tooltip>
          </template>
          <template v-if="!isTable">
            <Tooltip transfer content='保存图片' placement="bottom">
              <i class="iconfont iconchakantubiao" @click="isSaveImg"></i>
            </Tooltip>
          </template>
        </div>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="chart-counts" v-if="showCount">共 {{count}} 次</div>
    <div class='card-body'>
      <div>
        <skeleton
          v-if="loading"
          type='listcom'
          active
          :options="skeletonOption"
        />
        <div v-else-if="count === 0" class="empty" :style="{height: height + 'px'}">
          <img src="../../assets/imgs/empty.png">
        </div>
        <slot v-else></slot>
      </div>
    </div>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image'
import { Message, Tooltip, Spin } from 'view-design'
import { loadingSpin, setFilesExport } from '@/utils/tools'
export default {
  name: 'ChardCard',
  data () {
    return {
      skeletonOption: {
        row: 8,
        lineHight: 20
      }
    }
  },

  props: {
    cardId: {
      required: true,
      type: String,
      default: 'default-root'

    },
    label: {
      type: String,
      default: '默认图表'
    },
    count: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 400
    },
    loading: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: ''
    },
    showCount: {
      type: Boolean,
      default: false
    },
    isExport: {
      type: Boolean,
      default: true
    },
    export: {
      type: Function
    },
    isTable: {
      type: Boolean,
      default: false
    },
    isToggle: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Tooltip
  },

  computed: {},

  methods: {
    async isSaveImg() {
      loadingSpin()
      const el = document.getElementById(this.cardId)
      try {
        const blob = await domtoimage.toBlob(el)
        setFilesExport(blob, this.label)
        Spin.hide()
        Message.success({
          content: '图片下载成功',
          duration: 2
        })
      } catch (error) {
        Message.error({
          content: '图片下载失败',
          duration: 2
        })
      }
    },
    exportHandle() {
      this.export()
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
