<template>
  <div style="width:100%">
    <div :id="chartID"></div>
  </div>
</template>

<script>
import DataSet from '@antv/data-set'
import { Chart, registerShape, Util, registerInteraction } from '@antv/g2'
function getTextAttrs(cfg) {
  return {
    ...cfg.defaultStyle,
    ...cfg.style,
    fontSize: cfg.data.size,
    text: cfg.data.text,
    textAlign: 'center',
    fontFamily: cfg.data.font,
    fill: cfg.color || (cfg.defaultStyle && cfg.defaultStyle.stroke),
    textBaseline: 'Alphabetic'
  }
}

export default {
  name: 'WordCloud',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartID: {
      type: String,
      default: 'containers'
    },
    color: {
      type: Array,
      default: () => [
        '#26BFEF',
        '#24C6B8',
        '#F46E7E',
        '#9989F6',
        '#FFE373',
        '#FFBC47',
        '#39CB7C',
        '#E9687E',
        '#9ED46E',
        '#2AB7FF'
      ]
    },
    height: {
      type: Number,
      default: 400
    },
    rotate: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    isTooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData(val) {
      this.chart.destroy()
      if (val.length) {
        this.initWordCloudChart(val)
      }
    }
  },
  mounted() {
    this.initWordCloudChart(this.chartData)
  },
  methods: {
    initWordCloudChart(data) {
      if (!data.length) return
      // 给point注册一个词云的shape
      registerShape('point', 'cloud', {
        draw(cfg, container) {
          const attrs = getTextAttrs(cfg)
          const textShape = container.addShape('text', {
            attrs: {
              ...attrs,
              x: cfg.x,
              y: cfg.y
            }
          })
          if (cfg.data.rotate) {
            Util.rotate(textShape, (cfg.data.rotate * Math.PI) / 180)
          }
          return textShape
        }
      })
      const dv = new DataSet.View().source(data)
      const range = dv.range('value')
      const min = range[0]
      const max = range[1]
      const width = this.$el.clientWidth
      const deg = this.rotate
      dv.transform({
        type: 'tag-cloud',
        fields: ['name', 'value'],
        size: [width, this.height],
        font: 'Verdana',
        padding: 0,
        timeInterval: 5000, // max execute time
        rotate() {
          let random = ~~(Math.random() * 4) % 4
          if (random === 2) {
            random = 0
          }
          return random * deg // 0, 90, 270
        },
        fontSize(d) {
          if (d.value) {
            return (((d.value - min) / (max - min)) * (80 - 24) + 24) || 24
          }
          return 0
        }
      })
      // 实例化图表
      this.chart = new Chart({
        container: this.chartID,
        height: this.height,
        autoFit: true,
        padding: 0
      })
      // 装填数据
      this.chart.data(dv.rows)
      // 设置度量
      this.chart.scale({
        x: { nice: false },
        y: { nice: false }
      })
      // 设置图例
      this.chart.legend(false)
      // 设置坐标轴
      this.chart.axis(false)
      // 设置浮窗
      // this.chart.tooltip(this.isTooltip)
      if (this.isTooltip) {
        this.chart.tooltip({
          showMarkers: false,
          showTitle: false,
          customItems: (items) => {
            return items.map(item => ({
              ...item,
              val: item.data.value
            }))
          },
          itemTpl: '<li class="g2-tooltip-list-item"><span style="background:{color}" class="g2-tooltip-marker"></span>{name}<span style="margin-right: 8px;"></span>{val}</li>'
        })
      } else {
        this.chart.tooltip(false)
      }
      this.chart.coordinate().reflect()
      // 绘制图形属性
      this.chart
        .point()
        .position('x*y')
        .color('name')
        .shape('cloud')
      this.chart.interaction('element-active')

      registerInteraction('cursor-cus', {
        showEnable: [
          { trigger: 'plot:mouseenter', action: 'cursor:pointer' },
          { trigger: 'plot:mouseleave', action: 'cursor:default' }
        ]
      })
      this.chart.interaction('cursor-cus')
      // 渲染图形
      this.chart.render()

      // 绑定点击事件
      this.chart.on('click', event => {
        if (event.data) {
          const { data: { data: { name, value } } } = event
          this.$emit('cloud', { name, value })
        }
      })
    }
  },
  destroyed() {
    this.chart.destroy()
  }
}
</script>
