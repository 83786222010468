<template>
  <div class="analyze-page-wrap">
    <div class="header-wrap">
      <div class="label-wrap">
        <div class="label">选择日期：</div>
        <div class="value">
          <DateItem :lists="date" :activeKey="current" @active="handleDateItem" />
          <DatePicker
            style="width: 240px"
            type="daterange"
            v-model="dateRange"
            :clearable="false"
            placeholder="起始时间~结束时间"
            @on-change="handleDate"
          />
        </div>
      </div>
      <div class="btn-wrap">
        <Button type="default" style="margin-right: 12px" @click="handleClear">清空</Button>
        <Button type="primary" @click="handleSearch">搜索</Button>
      </div>
    </div>
    <div class="content-wrap">
      <ChardCard
        label="访问趋势"
        cardId="visit"
        :count="visitTrend.count"
        :showCount="true"
        :loading="visitTrend.loading"
        :export="() => handleExport('visitTrend', '访问趋势')"
      >
        <Curved
          lineColor="#1d8eff"
          fillColor="#deefff"
          style="margin-top: 24px"
          chartID="visitTrend"
          :height="300"
          tooltip="访问次数"
          :chartData="visitTrend.data"
        />
      </ChardCard>
      <Row :gutter="16">
        <Col span="12">
          <ChardCard
            label="生物标志物关注度"
            cardId="biomarker"
            :count="biomarker.count"
            :showCount="true"
            :loading="biomarker.loading"
            :export="() => handleExport('biomarker', '生物标志物关注度')"
          >
            <RankTabel :columns="biomarkerColumns" :data="biomarker.data" />
            <!-- <div class="pager-footer">
              <Page
                :current="biomarker.page"
                :total="biomarker.count"
                show-elevator
                @on-change="handlePageChange('biomarker', $event)"
              />
            </div> -->
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="会议关注度"
            cardId="meeting"
            :count="meeting.count"
            :showCount="true"
            :loading="meeting.loading"
            :export="() => handleExport('meeting', '会议关注度')"
          >
            <RankTabel :columns="meetingColumns" :data="meeting.data" />
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <ChardCard
            label="主题下载排名"
            cardId="biomarker"
            :count="meetingDownload.count"
            :showCount="true"
            :loading="meetingDownload.loading"
            :export="() => handleExport('meetingDownload', '主题下载排名')"
          >
            <RankTabel :columns="meetingDownloadColumns" :data="meetingDownload.data" />
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="主题转载排名"
            cardId="meeting"
            :count="meetingReprint.count"
            :showCount="true"
            :loading="meetingReprint.loading"
            :export="() => handleExport('meetingReprint', '主题转载排名')"
          >
            <RankTabel :columns="meetingReprintColumns" :data="meetingReprint.data" />
          </ChardCard>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { DatePicker, Button, Row, Col } from 'view-design'
import dayjs from 'dayjs'
import DateItem from 'views/system/components/DateItem'
import ChardCard from 'components/ChardCard'
import RankTabel from 'components/Table'
import { BaseServe } from '@/server'
import { saveAs } from 'file-saver'
import { Curved } from 'components/Charts'
import { meetingTrend, meetingBiomarker, meetingAttention, meetingDownload, meetingReprint } from '@/api/tracking/'
import { sortRender } from '@/views/literature/components/Dashboard/config'

export default {
  components: {
    DateItem,
    DatePicker,
    Button,
    ChardCard,
    Curved,
    RankTabel,
    Row,
    Col
  },
  data() {
    return {
      date: [
        {
          key: '1',
          label: '今天',
          value: '1'
        },
        {
          key: '7',
          label: '近7天',
          value: '7'
        },
        {
          key: '30',
          label: '近30天',
          value: '30'
        }
      ],
      biomarkerColumns: [
        {
          title: '排名',
          key: 'index',
          render: (h, params) => {
            return sortRender(h, params)
          },
          width: 120
        },
        {
          title: 'Biomarker名称',
          align: 'center',
          key: 'name'
        },
        {
          title: '搜索数',
          align: 'center',
          key: 'value'
        }
      ],
      meetingColumns: [
        {
          title: '排名',
          key: 'index',
          render: (h, params) => {
            return sortRender(h, params)
          },
          width: 120
        },
        {
          title: '会议名称',
          align: 'center',
          key: 'name'
        },
        {
          title: '搜索数',
          align: 'center',
          key: 'value'
        }
      ],
      meetingDownloadColumns: [
        {
          title: '排名',
          key: 'index',
          render: (h, params) => {
            return sortRender(h, params)
          },
          width: 120
        },
        {
          title: '所属会议',
          align: 'center',
          key: 'name'
        },
        {
          title: '下载数',
          align: 'center',
          key: 'value'
        }
      ],
      meetingReprintColumns: [
        {
          title: '排名',
          key: 'index',
          render: (h, params) => {
            return sortRender(h, params)
          },
          width: 120
        },
        {
          title: '所属会议',
          align: 'center',
          key: 'name'
        },
        {
          title: '转载数',
          align: 'center',
          key: 'value'
        }
      ],
      current: '7',
      dateRange: [],
      visitTrend: {
        count: 0,
        loading: false,
        data: []
      },
      biomarker: {
        count: 0,
        loading: false,
        data: [],
        page: 1
      },
      meeting: {
        count: 0,
        loading: false,
        data: [],
        page: 1
      },
      meetingDownload: {
        count: 0,
        loading: false,
        data: [],
        page: 1
      },
      meetingReprint: {
        count: 0,
        loading: false,
        data: [],
        page: 1
      },
      exportUrlMap: {
        visitTrend: '/meeting/behavior/dashboard/trend/export/',
        biomarker: '/meeting/behavior/dashboard/biomarker/export/',
        meeting: '/meeting/behavior/dashboard/meeting/export/',
        meetingDownload: '/meeting/behavior/dashboard/topic_download/export/',
        meetingReprint: '/meeting/behavior/dashboard/topic_share/export/'
      }
    }
  },
  computed: {
    dateParams() {
      if (this.current) {
        return {
          created_at__date__gte: dayjs()
            .subtract(this.current, 'day')
            .format('YYYY-MM-DD'),
          created_at__date__lte: dayjs().format('YYYY-MM-DD')
        }
      }
      return {
        created_at__date__gte: dayjs(this.dateRange[0]).format('YYYY-MM-DD'),
        created_at__date__lte: dayjs(this.dateRange[1]).format('YYYY-MM-DD')
      }
    }
  },
  created() {
    this.fetchData('all')
  },
  methods: {
    fetchData(type) {
      if (type === 'all' || type === 'visit') {
        this.visitTrend.loading = true
        meetingTrend(this.dateParams).then(res => {
          this.visitTrend.count = res.data.all_count
          this.visitTrend.loading = false
          this.visitTrend.data = res.data.data
        })
      }
      if (type === 'all' || type === 'biomarker') {
        this.biomarker.loading = true
        meetingBiomarker({
          ...this.dateParams,
          page: this.biomarker.page
        }).then(res => {
          this.biomarker.count = res.data.all_count
          this.biomarker.loading = false
          this.biomarker.data = res.data.data
        })
      }
      if (type === 'all' || type === 'meeting') {
        this.meeting.loading = true
        meetingAttention(this.dateParams).then(res => {
          this.meeting.count = res.data.all_count
          this.meeting.loading = false
          this.meeting.data = res.data.data
        })
      }
      if (type === 'all' || type === 'meetingDownload') {
        this.meetingDownload.loading = true
        meetingDownload(this.dateParams).then(res => {
          this.meetingDownload.count = res.data.all_count
          this.meetingDownload.loading = false
          this.meetingDownload.data = res.data.data
        })
      }
      if (type === 'all' || type === 'meetingReprint') {
        this.meetingReprint.loading = true
        meetingReprint(this.dateParams).then(res => {
          this.meetingReprint.count = res.data.all_count
          this.meetingReprint.loading = false
          this.meetingReprint.data = res.data.data
        })
      }
    },
    handleDateItem(key) {
      this.current = key
      this.dateRange = []
    },
    handleDate(date) {
      this.dateRange = date
      this.current = ''
    },
    handleClear() {
      this.dateRange = []
      this.current = '7'
      this.fetchData('all')
    },
    handleSearch() {
      this.fetchData('all')
    },
    handleExport(type, name) {
      BaseServe({
        url: this.exportUrlMap[type],
        method: 'get',
        responseType: 'blob',
        params: {
          ...this.dateParams
        }
      }).then(res => {
        saveAs(res, `${name}.xlsx`)
      })
    },
    handlePageChange(type, page) {
      console.log(type, page)
      this[type].page = page
      this.fetchData(type)
    }
  }
}
</script>

<style lang="less" scoped>
.analyze-page-wrap {
  margin-bottom: 24px;
}
.header-wrap {
  padding: 22px 32px;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  display: flex;
  justify-content: space-between;

  .label-wrap {
    display: flex;
    align-items: center;

    .label {
      color: #969fa8;
      font-size: 16px;
    }

    .value {
      display: flex;
    }
  }
}
.content-wrap {
  margin-top: 12px;
  background-color: #fff;
  padding: 32px;
  border-radius: 12px;
}

.pager-footer {
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
}
</style>
